/* TODO: convert to async funcs where possible */

/**
 * Cart API helper methods
 * ------------------------------------------------------------------------------
 * Reusable code for cart API methods
 *
 */

/**
 * Returns the state object of the cart
 * @returns {Promise} Resolves with the state object of the cart (https://help.shopify.com/en/themes/development/getting-started/using-ajax-api#get-cart)
 */
export function getState() {
    return cart();
  }
  
  /**
   * Returns the index of the cart line item
   * @param {string} key The unique key of the line item
   * @returns {Promise} Resolves with the index number of the line item
   */
  export function getItemIndex(key) {
    validateKey(key);
  
    return cart().then((state) => {
      const matched = state.items.find((item, idx) => {
        item.index = idx + 1;
        return item.key === key;
      });
  
      if (!matched) {
        return Promise.reject(
          new Error('Theme Cart: Unable to match line item with provided key')
        );
      }
  
      return matched.index;
    });
  }
  
  /**
   * Fetches the line item object
   * @param {string} key The unique key of the line item
   * @returns {Promise} Resolves with the line item object (See response of cart/add.js https://help.shopify.com/en/themes/development/getting-started/using-ajax-api#add-to-cart)
   */
  export function getItem(key) {
    validateKey(key);
  
    return cart().then((state) => {
      let lineItem = null;
  
      state.items.forEach((item) => {
        lineItem = item.key === key ? item : lineItem;
      });
  
      if (lineItem === null) {
        return Promise.reject(
          new Error('Theme Cart: Unable to match line item with provided key')
        );
      }
  
      return lineItem;
    });
  }
  
  /**
   * Add a new line item to the cart
   * @param {number} id The variant's unique ID
   * @param {object} options Optional values to pass to /cart/add.js
   * @param {number} options.quantity The quantity of items to be added to the cart
   * @param {object} options.properties Line item property key/values (https://help.shopify.com/en/themes/liquid/objects/line_item#line_item-properties)
   * @returns {Promise} Resolves with the line item object (See response of cart/add.js https://help.shopify.com/en/themes/development/getting-started/using-ajax-api#add-to-cart)
   */
  export function addItem(id, options = {}) {
    validateID(id);
  
    return cartAdd(id, options.quantity, options.properties);
  }
  
  /**
   * Add multiple new line items to the cart
   * @param {array} items List of objects including the id and quantities of each unique variant
   */
  export function addItems(items) {
    const config = getDefaultRequestConfig();
  
    config.method = 'POST';
    config.body = JSON.stringify({ items });
  
    return fetchJSON('/cart/add.js', config);
  }
  
  /**
   * Add a new line item to the cart from a product form
   * @param {object} form DOM element which is equal to the <form> node
   * @returns {Promise} Resolves with the line item object (See response of cart/add.js https://help.shopify.com/en/themes/development/getting-started/using-ajax-api#add-to-cart)
   */
  export function addItemFromForm(form) {
    validateForm(form);
  
    const formData = new FormData(form);
    validateID(parseInt(formData.get('id'), 10));
  
    return cartAddFromForm(formData);
  }
  
  /**
   * Changes the quantity and/or properties of an existing line item.
   * @param {string} key The unique key of the line item (https://help.shopify.com/en/themes/liquid/objects/line_item#line_item-key)
   * @param {object} options Optional values to pass to /cart/add.js
   * @param {number} options.quantity The quantity of items to be added to the cart
   * @param {object} options.properties Line item property key/values (https://help.shopify.com/en/themes/liquid/objects/line_item#line_item-properties)
   * @returns {Promise} Resolves with the state object of the cart (https://help.shopify.com/en/themes/development/getting-started/using-ajax-api#get-cart)
   */
  export function updateItem(key, options = {}) {
    validateKey(key);
    validateOptions(options);
  
    return getItemIndex(key).then((line) => cartChange(line, options));
  }
  
  /**
   *
   * Changes the quantity of an existing line item through it's variant id, useful when the key is not accessable.
   * @param {integer} variant.id
   * @param {number} quantity Setting to 0 will remove the item
   */
  export function updateItemQuantityById(id, qty) {
    return cartUpdateQuantityById(id, qty);
  }
  
  /**
   * Removes a line item from the cart
   * @param {string} key The unique key of the line item (https://help.shopify.com/en/themes/liquid/objects/line_item#line_item-key)
   * @returns {Promise} Resolves with the state object of the cart (https://help.shopify.com/en/themes/development/getting-started/using-ajax-api#get-cart)
   */
  export function removeItem(key) {
    validateKey(key);
  
    return getItemIndex(key).then((line) => cartChange(line, { quantity: 0 }));
  }
  
  /**
   * Sets all quantities of all line items in the cart to zero. This does not remove cart attributes nor the cart note.
   * @returns {Promise} Resolves with the state object of the cart (https://help.shopify.com/en/themes/development/getting-started/using-ajax-api#get-cart)
   */
  export function clearItems() {
    return cartClear();
  }
  
  /**
   * Gets all cart attributes
   * @returns {Promise} Resolves with the cart attributes object
   */
  export function getAttributes() {
    return cart().then((state) => state.attributes);
  }
  
  /**
   * Sets all cart attributes
   * @returns {Promise} Resolves with the cart state object
   */
  export function updateAttributes(attributes) {
    return cartUpdate({ attributes });
  }
  
  /**
   * Sets all quantities by indexes
   * @returns {Promise} Resolves with the cart state object
   */
  export function updateQuantitiesByIndexes(indexes) {
    return cartUpdate({ updates: indexes });
  }
  
  /**
   * Clears all cart attributes
   * @returns {Promise} Resolves with the cart state object
   */
  export function clearAttributes() {
    return getAttributes().then((attributes) => {
      for (const key in attributes) {
        if (attributes[key]) {
          attributes[key] = '';
        }
      }
      return updateAttributes(attributes);
    });
  }
  
  /**
   * Gets cart note
   * @returns {Promise} Resolves with the cart note string
   */
  export function getNote() {
    return cart().then((state) => state.note);
  }
  
  /**
   * Sets cart note
   * @returns {Promise} Resolves with the cart state object
   */
  export function updateNote(note) {
    return cartUpdate({ note });
  }
  
  /**
   * Clears cart note
   * @returns {Promise} Resolves with the cart state object
   */
  export function clearNote() {
    return cartUpdate({ note: '' });
  }
  
  /**
   * Get estimated shipping rates.
   * @returns {Promise} Resolves with response of /cart/shipping_rates.json (https://help.shopify.com/en/themes/development/getting-started/using-ajax-api#get-shipping-rates)
   */
  export function getShippingRates() {
    return cartShippingRates();
  }
  
  /**
   * Request helper functions
   */
  
  function getDefaultRequestConfig() {
    return JSON.parse(
      JSON.stringify({
        credentials: 'same-origin',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json;',
        },
      })
    );
  }
  
  function fetchJSON(url, config) {
    return fetch(url, config).then((response) => {
      if (!response.ok) {
        throw response.json();
      }
      return response.json();
    });
  }
  
  function cart() {
    return fetchJSON('/cart.js', getDefaultRequestConfig());
  }
  
  function cartAdd(id, quantity, properties) {
    const config = getDefaultRequestConfig();
  
    config.method = 'POST';
    config.body = JSON.stringify({
      id,
      quantity,
      properties,
    });
  
    return fetchJSON('/cart/add.js', config);
  }
  
  function cartAddItems(ids, quantity, properties) {
    const config = getDefaultRequestConfig();
  
    config.method = 'POST';
    const items = ids.map((id) => ({
      id,
      quantity,
      properties,
    }));
    config.body = JSON.stringify({
      items,
    });
  
    return fetchJSON('/cart/add.js', config);
  }
  
  function cartAddFromForm(formData) {
    const config = getDefaultRequestConfig();
    delete config.headers['Content-Type'];
  
    config.method = 'POST';
    config.body = formData;
  
    return fetchJSON('/cart/add.js', config);
  }
  
  function cartChange(line, options = {}) {

    const config = getDefaultRequestConfig();
  
    config.method = 'POST';
    config.body = JSON.stringify({
      line,
      quantity: options.quantity,
      properties: options.properties,
    });
  
    return fetchJSON('/cart/change.js', config);
  }
  
  function cartUpdateQuantityById(id, qty) {
    const config = getDefaultRequestConfig();
    delete config.headers['Content-Type'];
  
    config.method = 'POST';
    config.body = new URLSearchParams(`quantity=${qty}&id=${id}`);
  
    return fetchJSON('/cart/change.js', config);
  }
  
  function cartClear() {
    const config = getDefaultRequestConfig();
    config.method = 'POST';
  
    return fetchJSON('/cart/clear.js', config);
  }
  
  function cartUpdate(body) {
    const config = getDefaultRequestConfig();
  
    config.method = 'POST';
    config.body = JSON.stringify(body);
  
    return fetchJSON('/cart/update.js', config);
  }
  
  function cartShippingRates() {
    return fetchJSON('/cart/shipping_rates.json', getDefaultRequestConfig());
  }
  
  /**
   * Validation helper functions
   */
  
  function validateKey(key) {
    if (typeof key !== 'string' || key.split(':').length !== 2) {
      throw new TypeError(
        'Theme Cart: Provided key value is not a string with the format xxx:xxx'
      );
    }
  }
  
  function validateQty(quantity) {
    if (typeof quantity !== 'number' || Number.isNaN(quantity)) {
      throw new TypeError(
        'Theme Cart: An object which specifies a quantity or properties value is required'
      );
    }
  }
  
  function validateID(id) {
    if (typeof id !== 'number' || Number.isNaN(id)) {
      throw new TypeError('Theme Cart: Variant ID must be a number');
    }
  }
  
  function validateProps(properties) {
    if (typeof properties !== 'object') {
      throw new TypeError('Theme Cart: Properties must be an object');
    }
  }
  
  function validateForm(form) {
    if (!(form instanceof HTMLFormElement)) {
      throw new TypeError(
        'Theme Cart: Form must be an instance of HTMLFormElement'
      );
    }
  }
  
  function validateOptions(options) {
    if (typeof options !== 'object') {
      throw new TypeError('Theme Cart: Options must be an object');
    }
  
    if (
      typeof options.quantity === 'undefined' &&
      typeof options.properties === 'undefined'
    ) {
      throw new Error(
        'Theme Cart: You muse define a value for quantity or properties'
      );
    }
  
    if (typeof options.quantity !== 'undefined') {
      validateQty(options.quantity);
    }
  
    if (typeof options.properties !== 'undefined') {
      validateProps(options.properties);
    }
  }