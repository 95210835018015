import { defineStore } from 'pinia';
import { ref, reactive, computed } from 'vue';
import { getState, clearItems, updateNote } from '../utils/cart';
import debounce from 'lodash/debounce';
import axios from 'axios';

export const useCartStore = defineStore('cart', () => {
  const state = reactive({
    loading: false,
    items: [],
  });

  const cartItems = computed(() => {
    return state.items.item_count ? state.items.item_count : 0;
  });

  const loadCartItems = async () => {
    const cart = await getState();
    state.items = cart;
  };

  const appendProductData = async () => {
    state.loading = true;
    try {
      state.items.items.forEach(async (product) => {
        const config = {
          method: 'get',
          url: `/products/${product.handle}?view=append`,
          headers: {
            'Content-Type': 'application/json',
          },
        };
        product.variants = {};
        product.metafields = {};
        const res = await axios(config);
        const data = await res.data;
        const productVariants = data.variants;
        product.variants = productVariants;
      });
      setTimeout(() => {
        state.loading = false;
      }, 600);
    } catch (err) {
      throw err;
    }
    return;
  };

  const updateOrderRef = debounce((e) => {
    updateNote(state.items.note);
  }, 1000);

  return {
    state,
    cartItems,
    loadCartItems,
    appendProductData,
    updateOrderRef,
  };
});
