<script setup>
import isEmpty from 'lodash-es/isEmpty';
import { image } from '../../utils/image';

const props = defineProps({
  media: {
    type: [Object, String],
    required: true,
    default: () => {},
  },
  hoverMedia: {
    type: [Object, String],
    required: false,
    default: () => {},
  },
  fixedSize: {
    type: String,
    required: false,
    default: '550x',
  },
  ratio: {
    type: Number,
    required: false,
    default: 0,
  },
  cover: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  hovering: {
    type: Boolean,
    required: false,
    default: () => false,
  },
  logoHeight: {
    type: Number,
    required: false,
    default: 35,
  },
});

const src = constructSrc(props.media);
const srcset = constructSrcSet(props.media);
const aspectRatio = findAspectRatio(props.media);

const hoverSrc = constructSrc(props.hoverMedia);
const hoverSrcset = constructSrcSet(props.hoverMedia);

function constructSrc(img) {
  const path = img ? img.src || img.originalSrc || img.url || img : null;
  if (typeof path === 'object' || !path) return null;
  return props.fixedSize ? image(path, props.fixedSize) : image(path);
}

function constructSrcSet(img) {
  const path = img ? img.src || img.originalSrc || img.url || img : null;
  if (typeof path === 'object' || !path || props.fixedSize) return null;

  let set = `${image(path, 200)} 100w`;
  if (img.width >= 200) set += `, ${image(path, 400)} 200w`;
  if (img.width >= 400) set += `, ${image(path, 800)} 400w`;
  if (img.width >= 600) set += `, ${image(path, 1200)} 600w`;
  if (img.width >= 800) set += `, ${image(path, 1600)} 800w`;
  if (img.width >= 1000) set += `, ${image(path, 2000)} 1000w`;
  if (img.width >= 1500) set += `, ${image(path, 3000)} 1500w`;
  if (img.width >= 2000) set += `, ${image(path, 4000)} 2000w`;
  if (img.width >= 4000) set += `, ${image(path, 4000)} 8000w`;

  return set;
}

function findAspectRatio(img) {
  switch (true) {
    case props.ratio > 0:
      return props.ratio;
    case !img || isEmpty(img):
      return 0.776;
    case img.aspect_ratio || img.aspect:
      return img.aspect_ratio ?? img.aspect;
    case !!img.width && !!img.height:
      return img.width / img.height;
    default:
      return null;
  }
}

function error(e) {
  e.target.parentNode.classList.add('error');
}

function loaded(e) {
  e.target.parentNode.classList.add('lazyloaded');
}
</script>

<template>
  <div
    :class="['vimg', { cover }]"
    :style="{
      '--aspect-ratio': aspectRatio,
      '--logo-height': `${logoHeight}%`,
    }"
  >
    <transition-group name="fade">
      <img
        :key="0"
        :src="src"
        :data-srcset="srcset"
        :class="['vimg__img', 'vimg__main', { lazyload: src }]"
        data-sizes="auto"
        :alt="media ? media.alt : 'image'"
        :onerror="error"
        @lazyloaded="loaded"
      />
      <template v-if="hoverSrc">
        <img
          v-show="hovering"
          :key="1"
          :src="hoverSrc"
          :srcset="hoverSrcset"
          :class="['vimg__img', 'vimg__img--hover']"
          sizes="auto"
          loading="lazy"
          :alt="media ? media.alt : 'image'"
        />
      </template>
    </transition-group>
  </div>
</template>

<style lang="scss" scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.35s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
    will-change: opacity;
  }
</style>
