// preloads an image
function preload(images, size) {
  for (let i = 0; i < images.length; i++) {
    const image = images[i];
    new Image().src = getSizedImageUrl(image, size);
  }
}

// add an image size
function getSizedImageUrl(src, size) {
  if (size === null) {
    return src;
  }

  if (size === 'master') {
    return removeProtocol(src);
  }

  const match = src.match(
    /\.(jpg|jpeg|gif|webp|png|bmp|bitmap|tiff|tif)(\?v=\d+)?$/i
  );

  if (match) {
    const prefix = src.split(match[0]);
    const suffix = match[0];

    return removeProtocol(`${prefix[0]}_${size}${suffix}`);
  }
  return null;
}

function removeProtocol(path) {
  return path.replace(/http(s)?:/, '');
}

export const image = (src, size = '750x') => getSizedImageUrl(src, size);

export const preloadImages = (imgArray, size = '750x') =>
  preload(imgArray, size);
